<template>
  <v-sheet>
    <div class="text-center">
      <div style="float: left" class="font-weight-light text-h6">{{id}}</div>
      <div
        class="d-flex justify-center my-5"
        :style="`font-size: ${headerFontSize}px`"
      >
        <div v-if="processType">
          <span v-if="processDirection == 'Input'">(In)</span>
          <span v-if="processDirection == 'Output'">(Out)</span>
          {{ processType.name }}
        </div>
        <v-divider v-if="product && processType" vertical class="mx-3" />
        <div v-if="product">
          {{ product.name }}
        </div>
        <v-divider v-if="location" vertical class="mx-3" />
        <div v-if="location">
          {{ location.name }}
        </div>
      </div>
      <qriously :value="qrCodeUrl" :size="qrSize" />
      <div :style="`font-size: ${memoFontSize}px`" v-if="memo">
        {{ memo }}
      </div>

      <div class="d-flex flex-column justify-center my-6 d-print-none">
        <a :href="qrCodeUrl">{{ qrCodeUrl }}</a>
        <div class="d-flex">
          <v-text-field
            class="mx-4 my-4"
            outlined
            label="QR Size"
            v-model="qrSize"
          />
          <v-text-field
            class="mx-4 my-4"
            outlined
            label="Header Font Size"
            v-model="headerFontSize"
          />
          <v-text-field
            class="mx-4 my-4"
            outlined
            label="Memo Font Size"
            v-model="memoFontSize"
          />
        </div>
        <div class="my-2">
          <v-btn class="mx-4" @click="goBack()">Back</v-btn>
          <v-btn class="mx-4" @click="print()">Print</v-btn>
          <v-btn class="mx-4" @click="edit()">Edit</v-btn>
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { parseDomain } from "parse-domain";
import { getDynamicQrCode } from "@/services/dynamicQrCode";

export default {
  data: () => {
    return {
      worksheetTypeId: null,
      businessType: null,
      url: null,
      name: null,
      processType: null,
      processDirection: null,
      location: null,
      product: null,
      deductibleWeight: 0,
      targetWeight: 0,
      memo: null,
      domainUrl: null,
      qrSize: 800,
      headerFontSize: 100,
      memoFontSize: 100,
      entryPhoto: true,
      generated: false,
      uuid: null,
      id: null
    };
  },
  computed: {
    ...mapGetters("processes", ["processTypes"]),
    ...mapGetters("locations", ["locations"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    qrCodeUrl() {
      if(this.uuid){
        return `${this.domainUrl}/qr-dispatch/${this.uuid}/`;
      }
      else{ return null; }
    },
    worksheetTypeFields() {
      if (
        this.worksheetTypeDefinitions &&
        this.worksheetTypeDefinitions.length > 0 &&
        this.worksheetTypeId
      ) {
        return this.worksheetTypeDefinitions.find(
          (t) => t.id == this.worksheetTypeId
        );
      } else {
        return {};
      }
    },
  },
  async mounted() {
    this.setQrSize();
    window.addEventListener("resize", this.setQrSize);
    await this.fetchWorksheetTypeDefinitions();

    this.uuid = this.$route.params.uuid;
    this.initWorksheet(this.uuid);

    const parseResult = parseDomain(window.location.hostname);
    const { subDomains, domain, topLevelDomains } = parseResult;

    if (domain) {
      const subDomain = subDomains && subDomains.length > 0 ? subDomains[0]:'factory';
      const topLevel = topLevelDomains.join(".");
      this.domainUrl = `https://${subDomain}.${domain}.${topLevel}/#`;
    } else {
      this.domainUrl = `http://localhost:${process.env.VUE_APP_PORT}/#`;
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.setQrSize);
  },

  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    ...mapActions("locations", ["loadLocations"]),
    ...mapActions("messages", ["addMessage", "addErrorMessage"]),
    ...mapActions("navigation", ["hideDrawer"]),
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),
    ...mapActions("categoryStore", ["fetchCategory"]),

    async initWorksheet(uuid) {
      const qrCode = await this.loadQrCode(uuid);
      if (qrCode) {
        this.id = qrCode.id;
        this.businessType = qrCode.businessType;
        const steps = JSON.parse(qrCode.jsonData);
        const postStep = steps[0];
        this.worksheetTypeId = postStep.type;
        if (postStep.params) {
          if (this.worksheetTypeFields.hasProcessType) {
            const processTypeId = postStep.params.processTypeId;
            this.processType = await this.loadCategory(processTypeId);
          }
          if (this.worksheetTypeFields.hasLocation) {
            const locationId = postStep.params.locationId;
            this.location = await this.loadCategory(locationId);
          }
          if (this.worksheetTypeFields.hasProduct) {
            const productId = postStep.params.productId;
            this.product = await this.loadCategory(productId);
          }
          this.memo = postStep.params.memo;
          this.targetWeight = postStep.params.targetWeight;
          this.deductibleWeight = postStep.params.deductibleWeight;
          this.processDirection = postStep.params.processDirection;
        }
      }
    },
    async loadCategory(id) {
      if(id){
        return await this.fetchCategory(id)
          .then((category) => {
            return category;
          })
          .catch((error) => {
            this.addErrorMessage("Item not found; " + error.data.message);
            return null;
          });
      }
    },
    async loadQrCode(uuid) {
      return await getDynamicQrCode(uuid)
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          this.addErrorMessage("Error loading dynamicQrCode");
          return null;
        });
    },
    setQrSize() {
      this.qrSize = (window.innerWidth * 0.5).toFixed(0);
      this.headerFontSize = (this.qrSize * 0.05).toFixed(0);
      this.memoFontSize = (this.qrSize * 0.05).toFixed(0);
    },
    async print() {
      await this.hideDrawer();
      window.print();
    },
    edit(){
      if (this.businessType == "WORKSHEET") {
        this.$router.push({
          name: "worksheetQRCodeForm",
          params: { uuid: this.uuid },
        });
      } else {
        this.$router.push({
          name: "dynamicQrCodeEdit",
          params: { id: this.uuid },
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
